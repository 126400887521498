import { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";

import classes from "./chatbot.module.css";
import { ChatHistory, ElasticSearch, QuestionAnswer } from "../../sevices";
import ListDetail from "../../components/ListDetail";
import { ReactComponent as SendS } from "../../assets/SendS.svg";
import { ReactComponent as UserICon } from "../../assets/Chatbot.svg";
import { ReactComponent as ChatbotR } from "../../assets/ChatbotR.svg";
import { getDates, parseJSONWithFallback } from "../../Utils/Functions";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";

/**
@author      : Pankaj Kumar
@date        : 2023-01-19
@description : Component which renders the constant expanded chat bot box including the chat window and the input field & send button
*/
function Chatbot() {
  const param = useParams();

  const location = useLocation();

  const inputRef = useRef(null);
  const scrollBottom = useRef();
  const [fileType, setFileType] = useState(
    location?.pathname?.includes("file") ? "file" : "database"
  );
  const [active, setActive] = useState(
    location?.pathname?.includes("file") ? "file" : "database"
  );
  const [activeSearch, setActiveSearch] = useState(true);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSqlButton, setShowSqlButton] = useState(false);

  const [messages, setMessages] = useState([
    {
      question: "",
      answer: "How can I help you today?",
      sqlQuery: "",
      sourceDocuments: [],
    },
  ]);

  const [inputMessage, setInputMessage] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // If the pressed key is "Enter", trigger a click event on the button
      inputRef.current.click();
    }
  };

  /**
   * The function `handleSendMessage` is used to send a message, update the message state, clear the
   * input field, set the typing indicator, and process the message using ChatGPT.
   */
  const handleSendMessage = async () => {
    if (!inputMessage && !typing) return false;
    setTyping(true);
    const newMessage = {
      question: inputMessage,
      answer: "Typing...",
      userSide: true,
      sqlQuery: "",
      sourceDocuments: [],
    };
    setMessages([...messages, newMessage]);
    // setInputMessage("");
    let obj = {
      db_info_id: fileType === "database" ? param.id : null,
      file_id: fileType === "file" ? param.id : null,
      question: inputMessage,
      user_id: localStorage.getItem("userId"),
      is_folder: true,
    };
    // const result = await QuestionAnswer(obj);
    try {
      setLoading(true);
      if (activeSearch) {
        const result = await QuestionAnswer(obj);
        setShowSqlButton(false);
        if (result?.data?.status === false) {
          let copyInput = inputMessage;
          const newMessage = {
            sqlQuery: "",
            sourceDocuments: [],
            question: copyInput,
            answer:
              "Apologies, I couldn't find a suitable response to your question at the moment. It's possible that the topic is outside my current scope or that I require more information to assist you effectively. Could you please provide more details or try rephrasing your question?",
          };
          setMessages([...messages, newMessage]);
          setTyping(false);
          setInputMessage("");
          setLoading(false);
        } else {
          setLoading(false);
          if (inputMessage.trim() !== "") {
            const newMessage = {
              question: result?.data?.data?.question,
              answer: result?.data?.data?.answer_dataframe?.answer,
              sqlQuery: result?.data?.data?.answer_dataframe?.sql_query,
              sourceDocuments:
                result?.data?.data?.answer_dataframe?.source_documents,
            };
            /* update our message state with the updated value */
            setMessages([...messages, newMessage]);
            // const newMessages = [...messages, newMessage];

            /* setting the value of the `inputMessage` state to an empty string to clear the input field */
            setInputMessage("");
            scrollBottom.scrollTop = scrollBottom.clientHeight + 20;
            setTyping(false);
            /* set the typing indicator */

            /* Call the processMessages function which does the API calling with the new messages as the parameter */
            // await processMessages(newMessages);
            // if (messageContainer) {
            //   messageContainer.scrollTop = messageContainer.scrollHeight + 20;
            // }
          }
        }
      } else {
        const result = await ElasticSearch(obj);
        setShowSqlButton(false);
        if (result?.data?.status === false) {
          let copyInput = inputMessage;
          const newMessage = {
            sqlQuery: "",
            sourceDocuments: [],
            question: copyInput,
            answer:
              "Apologies, I couldn't find a suitable response to your question at the moment. It's possible that the topic is outside my current scope or that I require more information to assist you effectively. Could you please provide more details or try rephrasing your question?",
          };
          setMessages([...messages, newMessage]);
          setTyping(false);
          setInputMessage("");
          setLoading(false);
        } else {
          setLoading(false);
          if (inputMessage.trim() !== "") {
            // let final = "";
            // let res = result.data.result
            // res.forEach((data) => (final += data.filename + " " + data.page_number + "\n"));
            const newMessage = {
              question: obj.question,
              answer: `Total occurences: ${result.data.total_occurances}`,
              sqlQuery: "",
              sourceDocuments: result.data.source_documents,
            };
            /* update our message state with the updated value */
            setMessages([...messages, newMessage]);

            /* setting the value of the `inputMessage` state to an empty string to clear the input field */
            setInputMessage("");
            scrollBottom.scrollTop = scrollBottom.clientHeight + 20;
            setTyping(false);
          }
        }
      }
    } catch (error) {
      setTyping(false);
      setLoading(false);
    }
  };

  /**
   * The function `processMessages` takes an array of chat messages, converts them into API queries,
   * retrieves the last query, sends it to an API endpoint, and updates the chat messages state with
   * the response.
   * @param {Message[]} chatMessages - An array of objects representing chat messages. Each object has
   * the following properties:
   */

  function change(values) {
    let a = [];
    if (values) {
      for (const [key, value] of Object.entries(values)) {
        if (key) {
          const getvaluesFromObj = Object.values(value);
          a.push({ [key]: getvaluesFromObj });
        }
      }
    }
    return a;
  }

  // Ciatation part

  const ciatationFun = (value) => {
    let a = value.split("/");
    return a[a.length - 1];
  };

  useEffect(() => {
    // Check if the messageContainerRef exists
    if (scrollBottom.current && messages && messages.length > 0) {
      const lastMessage = scrollBottom.current;
      if (lastMessage && lastMessage.scrollIntoView) {
        // Scroll to the last message smoothly
        lastMessage.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [messages]);
  useEffect(() => {
    let checkType = location.pathname.search("/chat/file/");
    if (checkType === -1) {
      setFileType("database");
    } else {
      setFileType("file");
    }
  }, [location, param, active, fileType]);

  useEffect(() => {
    (async () => {
      let obj = {
        db_info_id: fileType === "database" ? param.id : null,
        file_id: fileType === "file" ? param.id : null,
        chat_type: "file",
        user_id: localStorage.getItem("userId"),
      };
      if (obj.file_id !== null || obj.db_info_id !== null)
        try {
          const result = await ChatHistory(obj);
          if (result.message) {
            toast.error(result.message, {
              position: "top-right",
            });
          }
          const changeType = result?.data?.data.map((res, index) => {
            if (location?.pathname?.includes("database")) {
              return {
                question: res.Question,
                answer:  res.Answer,
                sqlQuery: res.SQL_Query,
                sourceDocuments: [],
              };
            }

            return {
              question: res.Question,
              answer:  res.Answer,
              sqlQuery: res.SQL_Query,
              sourceDocuments: [],
            };
          });
          setMessages(changeType);
        } catch (error) {}
    })();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.main}>
      <div className={classes.height}>
        <div className={classes.tab_btn}>
          <button
            className={active === "file" ? classes.btn_active : ""}
            onClick={() => {
              setActive("file");
              setMessages([
                {
                  question: "",
                  answer: "How can I help you today?",
                  sqlQuery: "",
                  sourceDocuments: [],
                },
              ]);
            }}
          >
            Files
          </button>
          <button
            className={active === "database" ? classes.btn_active : ""}
            onClick={() => {
              setActive("database");
              setMessages([
                {
                  question: "",
                  answer: "How can I help you today?",
                  sqlQuery: "",
                  sourceDocuments: [],
                },
              ]);
            }}
          >
            Database
          </button>
        </div>
        <div>
          <ListDetail
            active={active}
            selectedID={param.id}
            clearChat={() => {
              setMessages([
                {
                  question: "",
                  answer: "How can I help you today?",
                  sqlQuery: "",
                  sourceDocuments: [],
                },
              ]);
            }}
          />
        </div>
      </div>
      <div className={classes.chatMain}>
        <div className={classes.chatMainHeight}>
          <h2>{getDates(new Date())}</h2>
          <div className={classes.cc} ref={scrollBottom}>
            <div>
              {messages?.length === 0 && (
                <div>
                  <div className={classes.answerDiv}>
                    <div className={classes.icon}>
                      <ChatbotR />
                    </div>
                    <label className={classes.answer}>
                      How can I help you today?
                    </label>
                  </div>
                </div>
              )}
              {/* Display Ouestion and answer */}
              {messages?.map((res, index) => {
                if (res.user && typing) {
                  return (
                    <div className={classes.questionDiv}>
                      <label className={classes.question}>{res.question}</label>
                      <div className={classes.icon}>
                        <UserICon />
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index}>
                    {res?.question && (
                      <div className={classes.questionDiv}>
                        <label className={classes.question}>
                          {res.question}
                        </label>
                        <div className={classes.icon}>
                          <UserICon />
                        </div>
                      </div>
                    )}
                    {res.answer && (
                      <div className={classes.answerDiv}>
                        <div className={classes.icon}>
                          <ChatbotR />
                        </div>
                        <label>
                          <label className={classes.answer}>
                            {typeof res.answer === "string" ? (
                              <div>
                                {res.answer}
                                {/* Show Ciatation Part */}
                                {res.sourceDocuments &&
                                  res.sourceDocuments.length > 0 && (
                                    <div className={classes.blue}>
                                      <label className={classes.bold}>
                                        Citations:
                                      </label>

                                      {res.sourceDocuments.map((source) => {
                                        // if (index < 2) {
                                        let sourcePath = source.metadata.source;
                                        const index =
                                          sourcePath.indexOf("data");
                                        if (index !== -1) {
                                          sourcePath =
                                            sourcePath.substring(index);
                                        }
                                        let pageNo = 1;
                                        if (
                                          source.metadata.page !== undefined
                                        ) {
                                          pageNo = parseInt(
                                            source.metadata.page
                                          );
                                        }
                                        let isExcel = false;
                                        if (
                                          source.metadata.source.endsWith(
                                            ".xls"
                                          ) ||
                                          source.metadata.source.endsWith(
                                            ".xlsx"
                                          )
                                        ) {
                                          isExcel = true;
                                        }
                                        return (
                                          <ul className={classes.blue}>
                                            <li>
                                              Source:&nbsp;
                                              <a
                                                href={`${process.env.REACT_APP_BASEURL}/${sourcePath}#page=${pageNo}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {ciatationFun(
                                                  source.metadata.source
                                                )}
                                              </a>
                                              {source.metadata.page !==
                                                undefined &&
                                                !isExcel && (
                                                  <label>
                                                    &nbsp;Page:{pageNo}
                                                  </label>
                                                )}
                                            </li>
                                          </ul>
                                        );
                                        // }
                                      })}
                                    </div>
                                  )}
                                {/*  */}
                              </div>
                            ) : (
                              change(res.answer).map((res) => {
                                return (
                                  res &&
                                  Object.entries(res).map(
                                    ([key, value], index) => {
                                      return (
                                        <>
                                          <table
                                            key={index}
                                            className={classes.table}
                                          >
                                            <thead>
                                              <tr>
                                                <th>{key}</th>
                                              </tr>
                                            </thead>
                                            {value &&
                                              value.length > 0 &&
                                              value.map((ressss, indexx) => {
                                                return (
                                                  <tbody key={indexx}>
                                                    <tr>
                                                      <td>{ressss}</td>
                                                    </tr>
                                                  </tbody>
                                                );
                                              })}
                                          </table>
                                        </>
                                      );
                                    }
                                  )
                                );
                              })
                            )}
                          </label>
                        </label>
                        {/* For view Sql query */}
                        {res.sqlQuery && (
                          <>
                            <span></span>
                            <span>
                              <button
                                className={classes.sqlqueryButton}
                                onClick={() => {
                                  setShowSqlButton(!showSqlButton);
                                }}
                              >
                                {!showSqlButton ? "View" : "Hide"} SQL Query
                              </button>
                              {showSqlButton && (
                                <p className={classes.sqlquery}>
                                  {" "}
                                  {res.sqlQuery}
                                </p>
                              )}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={
            fileType === "file" && active === "file"
              ? classes.chatFooter
              : classes.chatFooterh
          }
          style={
            param.id ? { pointerEvents: "auto" } : { pointerEvents: "none" }
          }
        >
          <div className={classes.chatSearch}>
            <input
              type="text"
              placeholder="Type your question here"
              onChange={(e) => {
                setInputMessage(e.target.value);
              }}
              value={inputMessage}
              onKeyPress={handleKeyPress}
            />
            <button
              className={classes.buttonSend}
              ref={inputRef}
              onClick={handleSendMessage}
              disabled={loading}
            >
              <SendS />
            </button>
          </div>
          {fileType === "file" && active === "file" && (
            <div className={classes.chatFooterEnd}>
              <div>
                <label>Exact search</label>
                <Switch
                  value={activeSearch}
                  onChange={(e) => {
                    setActiveSearch(e.target.checked);
                  }}
                  defaultChecked
                />
                <label>AI search</label>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
